import React, { useState } from "react"
import Img from "gatsby-image"

import SimpleLayout from "../Layout/SimpleLayout"
import Pontuation from "../Pontuation/Pontuation"
import ProjectCard from "../ProjectCard/ProjectCard"

import styles from "./styles/portfolio.module.css"

const Filter = ({ value, label, filter, setFilter }) => (
  <h3
    className={`${styles.filter} ${filter === value ? styles.active : ""}`}
    onClick={() => setFilter(value)}
  >
    {label}
  </h3>
)

const Portfolio = ({ page, projects, headerImg, ...props }) => {
  const [filter, setFilter] = useState("")
  return (
    <SimpleLayout path={props.path}>
      <header className={styles.header}>
        <div className={styles.container}>
          <h1 className={styles.sectionTitle}>
            {page.title}
            <Pontuation>.</Pontuation>
          </h1>
          <h2 className={styles.sectionDescription}>{page.subtitle}</h2>
        </div>
        <Img fixed={headerImg.fixed} className={styles.headerImg} />
      </header>
      <section className={styles.projects}>
        <div className={styles.container}>
          <div className={styles.row}>
            <Filter
              value=""
              label="All"
              filter={filter}
              setFilter={setFilter}
            />
            <Filter
              value="mobile"
              label="Mobile"
              filter={filter}
              setFilter={setFilter}
            />
            <Filter
              value="web"
              label="Web"
              filter={filter}
              setFilter={setFilter}
            />
            <Filter
              value="other"
              label="Other"
              filter={filter}
              setFilter={setFilter}
            />
          </div>
          <div className={styles.cards}>
            {projects.map(({ node }) => (
              <ProjectCard
                key={node.id}
                icon={node.frontmatter.icon.childImageSharp.fluid}
                title={node.frontmatter.title}
                description={node.frontmatter.subtitle}
                slug={node.fields.slug}
              />
            ))}
          </div>
        </div>
      </section>
    </SimpleLayout>
  )
}

export default Portfolio
