import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"

import styles from "./styles.module.css"

const ProjectCard = ({ icon, slug, title, description, active }) => (
  <Link to={slug} className={`${styles.card} ${active ? styles.active : ""}`}>
    <Img fluid={icon} className={styles.icon} />
    <h1 className={styles.title}>{title}</h1>
    <p className={styles.description}>{description}</p>
  </Link>
)

export default ProjectCard
